import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manager: null,
  defaultFileStatus: null,
  defaultFolders: null,
  availableStatuses: null,
  registryRights: [],
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      { key: "index", name: "global:rentContract.index" },
      { key: "contractNo", name: "global:rentContract.contractNo" },
      { key: "contact.name", name: "global:rentContract.contact" },
      {
        key: "customerAssistant.firstName+lastName",
        name: "global:rentContract.customerAssistant",
      },

      { key: "accountDay", name: "global:rentContract.accountDay" },
      { key: "status.name", name: "global:rentContract.status" },
      {
        key: "foreignContractNo",
        name: "global:rentContract.foreignContractNo",
      },
    ],
    selectedSearchFields: [
      "index",
      "contractNo",
      "foreignContractNo",
      "contact.name",
    ],
    dictionaryFields: ["rentContractType", "servicePriorityContract"],
    url: "rentcontract",
  },
};

const rentContractReducer = createSlice({
  name: "rentContractReducer",
  initialState,
  reducers: {
    setRegistryRights: (state, { payload }) => {
      state.registryRights = payload;
    },
    updateManager: (state, { payload }) => {
      state.manager = payload;
    },
    updateDefaultFileStatus: (state, { payload }) => {
      state.defaultFileStatus = payload;
    },
    updateDefaultFolders: (state, { payload }) => {
      state.defaultFolders = payload;
    },
    updateAvailableStatuses: (state, { payload }) => {
      state.availableStatuses = payload;
    },
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRegistry: () => initialState,
  },
  extraReducers: {},
});

export const {
  updateRegistry,
  clearRegistry,
  updateManager,
  updateDefaultFileStatus,
  updateDefaultFolders,
  updateAvailableStatuses,
  setRegistryRights,
} = rentContractReducer.actions;

export default rentContractReducer.reducer;
